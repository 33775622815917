import BaseCrudService from "./baseCrud.service";

export default class CashFlowService extends BaseCrudService {
    constructor() {
        super("CashFlow");
    }

    listDonationsFromProject(cashFlowFilters) {
        return this.postRequest("GetDonationsItemsByProject", cashFlowFilters);
    }

    listAllToConciliate(cashFlowFilters) {
        return this.postRequest("DonationBase", cashFlowFilters);
    }

    example() {
        return this.postRequest("ExampleTree");
    }

    listAllToConciliateTicket(cashFlowFilters) {
        return this.postRequest("DonationBaseTicket", cashFlowFilters);
    }

    transfer(cashFlowTransfer, listSelectedItems) {
        return this.postRequest("Transfer", { cashFlowTransfer: cashFlowTransfer, selectedItems: listSelectedItems });
    }

    conciliatePayRollDeduction(donationsId, toStatus) {
        return this.postRequest("ConciliatePayRollDeduction", { donationsId: donationsId, toStatus: toStatus });
    }

    conciliateTicket(ticketCartIds, toStatus) {
        return this.postRequest("ConciliateTicket", { ticketCartIds: ticketCartIds, toStatus: toStatus });
    }

    resendTicket(cartIds) {
        return this.postRequest("ResendTicket", { cartIds: cartIds });
    }

    deleteTicket(cartIds) {
        return this.postRequest("DeleteTicket", { cartIds: cartIds });
    }

    transferToInstitutionAccept(selectedItems) {
        return this.postRequest("TransferToInstitutionAccept", { selectedItems: selectedItems });
    }

    transferToInstitution(cashFlowTransfer) {
        return this.postRequest("TransferToInstitution", cashFlowTransfer);
    }

    acceptPendingTransfer(selectedItems) {
        return this.postRequest("AcceptPendingTransfer", { selectedItems: selectedItems });
    }

    declinePendingTransfer(selectedItems) {
        return this.postRequest("DeclinePendingTransfer", { selectedItems: selectedItems });
    }

    conciliedToInstitution(selectedItems) {
        return this.postRequest("ConciliedToInstitution", { selectedItems: selectedItems });
    }

    dataStatusZero(cashFlowStatusZeroFilters) {
        return this.postRequest("DataStatusZero", cashFlowStatusZeroFilters);
    }

    downloadDbf(entityId){
        return this.getRequestBlob("DownloadDbf", {entityId: entityId});
    }
}