<template>
  <v-layout align-space-between column fill-height w-100>
      <a @click="downloadDbf()">Clique aqui pra fazer o Download</a>
  </v-layout>
</template>
<script type="plain/text">
import CashFlowService from "@/scripts/services/cashFlow.service.js";
export default {
  data() {
    return {
      cashFlowService: new CashFlowService()
    };
  },
  mounted() {
    this.downloadDbf();
  },
  methods: {
    downloadDbf() {
      this.cashFlowService.downloadDbf(this.$route.query.entityId).then(        
        function(response) {
          if (response != null) {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/excel" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "DBF" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          } else {
          }
        }.bind(this)
      );
    }
  }
};
</script>
<style scoped>
body {
  overflow: hidden;
}
</style>